import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
//import logo from './logo.svg';
//import './App.css';

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasAuthenticated,setIsAuthenticating]);
  //useEffect(onLoad, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }
  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    props.history.push("/");
  }

  return (!isAuthenticating &&

    <div id="wrapper">

    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-icon">
          <svg width="25px" height="27px" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 102.000000 109.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,109.000000) scale(0.100000,-0.100000)" fill="orange" stroke="none">
              <path d="M267 822 l-247 -247 0 -425 720 0 0 90 -630 0 0 292 404 418 416 -415 0 -515 90 0 0 545 -505 505 -248 -248z"/>
              <path d="M337 692 l-177 -177 0 -225 90 0 0 195 265 265 275 -275 -5 -369 -765 -5 0 -80 850 0 0 505 -173 173 c-95 94 -174 172 -177 172 -3 0 -85 -80 -183 -178z"/>
            </g>
          </svg>
        </div>
        <div className="sidebar-brand-text mx-2">
          tool shack
        </div>
      </a>

      <hr className="sidebar-divider my-0" />
      {isAuthenticated && (
      <span>
      <li className="nav-item">
        <a className="nav-link" href="geoip">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>IP address tools</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Language translation</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>PDF generation</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Website image capture</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Image manipulation</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Services APIs</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>About</span></a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Contact</span></a>
      </li>
      </span>
      )}
      {isAuthenticated ? (
      <li className="nav-item">
        <a className="nav-link" href="logout">
              <i className="fas fa-fw fa-chart-area"></i>
              <span onClick={handleLogout}>Logout</span></a>
      </li>
      ) : (
      <li className="nav-item">
        <a className="nav-link" href="login">
              <i className="fas fa-fw fa-chart-area"></i>
              <span>Login</span></a>
      </li>
      )}
    </ul>
    <div id="content-wrapper" className="d-flex flex-column">

      <div id="content">

        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <img src="images/testbanner-v1-banner-728x90.1584793535-5e7607bff412c.jpg" alt="example banner" />
        </nav>
        <div className="container-fluid">

          <div className="row">

            <div className="col-lg-8 mb-4">

              <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />

            </div>
          </div>

        </div>
      </div>

      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>&copy; tool shack 2020</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
  );
}

export default withRouter(App);
