import React, { useState } from "react";
import { API } from "aws-amplify";
import { HelpBlock, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import ReCAPTCHA from "react-google-recaptcha";

export default function Geoip(props) {
  const [geoipResult, setGeoipResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reCaptchaCompleted, setReCaptchaCompleted] = useState(false);
  const recaptchaRef = React.createRef();
  // eslint-disable-next-line
  const [content] = useState("");
  const [fields, handleFieldChange] = useFormFields({ ip: "" });

  async function handleSubmit(event) {
    event.preventDefault();

    // IP validation

    setIsLoading(true);
    try {
      const geoipResult = await getGeoipData(fields.ip);
      console.log(geoipResult);
      setGeoipResult(geoipResult);
      setIsLoading(false);
      //props.history.push("/");
    }
    catch (e) {
      alert(e);
      console.log(e);
      setIsLoading(false);
    }
  }

  function getGeoipData(ip) {
    var apiPath = "/geoip-pub/";
    if (props.isAuthenticated) {
      apiPath = "/geoip/";
    }
    var queryString = '';
    if (!props.isAuthenticated) {
      //console.log(recaptchaRef.current.getValue());
      //console.log(recaptchaRef);
      const recaptchaValue = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();
      setReCaptchaCompleted(false);
      queryString = "?recaptcha=" + recaptchaValue;
    }
    return API.get("notes", apiPath + ip + queryString, {}).then(response => response).catch(error => { console.log(error.response.data);
      alert('You need to be logged in to to use this service'); });

  }

  function validateForm() {
    if (props.isAuthenticated) {
      return fields.ip.length > 0;
    }
    else {
      return fields.ip.length > 0 && reCaptchaCompleted === true;
    }
  }

  function onChange(value) {
    setReCaptchaCompleted(true);
    console.log("Captcha value:", value);
  }

  function setIpfield(e) {
    //e.preventDefault();
    fields.ip = e.target.innerText;
    //handleFieldChange({ip:e.target.innerText});
    console.log(e.target.innerText);
  }

  return (
    <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">IP check</h6>
                </div>
                <div className="card-body">
                  Find the geographic location of an IP address, sometimes known as geoip or ipgeolocation.<br/>
                  Coming soon: check will also include checks against spam block lists, bot/crawler information lists.
                  <div className="Geoip">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="ip">
          <ControlLabel>Find the location of an IP address</ControlLabel>
          <FormControl
          autoFocus
            type="text"
            onChange={handleFieldChange}
            value={fields.ip}
          />
          <HelpBlock>examples: 
            USA <span onClick={setIpfield}>151.101.1.6</span>, 
            UK <span onClick={setIpfield}>78.147.69.183</span>, 
            Germany <span onClick={setIpfield}>138.201.233.252</span>,
            China <span  onClick={setIpfield}>36.96.23.123</span>
          </HelpBlock>
        </FormGroup>
        {!props.isAuthenticated && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LdWC-QUAAAAAOqTc0XpnTJDKFNMO5c-7ZyYibZU"
          onChange={onChange}
          className="mb-3"
        />
        )}
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Submit
        </LoaderButton>
      </form>
      {geoipResult && (
      <div className="geoipResults mt-3">
        <p>
          <img
            className="pr-2"
            src={geoipResult.country_flag}
            alt={geoipResult.country_name}
          />
          {geoipResult.city}, {geoipResult.state_prov}, {geoipResult.country_name}, 
          ISP: {geoipResult.isp}
          {geoipResult.connection_type && (
          <span>, Connection type: {geoipResult.connection_type}</span>
          )}
        </p>
      </div>
      )}

    </div>
                  <table id="example" className="display" width="100%">

                  </table>
                </div>
              </div>
  );
};
